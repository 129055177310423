@import url(https://fonts.googleapis.com/css2?family=Lato&family=Exo);
:root {
  --palette1: #f2cc8f;
  --palette2: #81b29a;
  --palette3: #3d405b;
  --palette4: #e07a5f;
  --palette5: #f4f1de;
  --white: #f0efeb;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #f4f1de;

  color: var(--palette5);
  background-color: #81b29a;
  background-color: var(--palette2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Content {
    height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.AppTitle {
    font-family: 'Exo', sans-serif;
    margin-top: 6rem;
    font-size: 5.8rem;
    color: var(--palette3);
}

.AppSubtitle {
    font-size: 2.2rem;
    color: var(--palette3);
}

.AppText {
    margin-top: 2rem;
    font-size: 1.1rem;
    text-align: center;
    max-width: 500px;
}

.Footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    margin: 0;
    padding: 5px;

    height: 40px;
}
