@import url('https://fonts.googleapis.com/css2?family=Lato&family=Exo');

:root {
  --palette1: #f2cc8f;
  --palette2: #81b29a;
  --palette3: #3d405b;
  --palette4: #e07a5f;
  --palette5: #f4f1de;
  --white: #f0efeb;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--palette5);
  background-color: var(--palette2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
