.Content {
    height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.AppTitle {
    font-family: 'Exo', sans-serif;
    margin-top: 6rem;
    font-size: 5.8rem;
    color: var(--palette3);
}

.AppSubtitle {
    font-size: 2.2rem;
    color: var(--palette3);
}

.AppText {
    margin-top: 2rem;
    font-size: 1.1rem;
    text-align: center;
    max-width: 500px;
}

.Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 5px;

    height: 40px;
}